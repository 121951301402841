import _ from "lodash";
import { Animations } from "../../../types";

export const setComma = (str: string | number): string => {
    const parts = _.toString(str).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
};

export const getInMotionValue = (
    animations: Animations,
    scrollYProgress: number
): number => {
    const totalAnimationLength = animations[2].end - animations[2].start;
    const nowScrollProgress = scrollYProgress - animations[2].start;
    const scrollRatio = nowScrollProgress / totalAnimationLength;

    const rv = animations[0] + animations[1] * scrollRatio;

    return rv;
};

export const getOutMotionValue = (
    animations: Animations,
    scrollYProgress: number
): number => {
    const totalAnimationLength = animations[2].end - animations[2].start;
    const nowScrollProgress = scrollYProgress - animations[2].start;
    const scrollRatio = nowScrollProgress / totalAnimationLength;

    const rv = animations[0] - animations[0] * scrollRatio;
    return rv;
};

export const getMotionValue = (
    animations: Animations[],
    scrollYProgress: number
) => {
    const inStartPoint = animations[0][2].start;
    const inEndPoint = animations[0][2].end;
    const outStartPoint = animations[1][2].start;
    const outEndPoint = animations[1][2].end;

    if (scrollYProgress > inStartPoint && scrollYProgress < inEndPoint) {
        return getInMotionValue(animations[0], scrollYProgress);
    } else if (
        scrollYProgress >= inEndPoint &&
        scrollYProgress <= outStartPoint
    ) {
        return animations[0][1];
    } else if (
        scrollYProgress > outStartPoint &&
        scrollYProgress < outEndPoint
    ) {
        return getOutMotionValue(animations[1], scrollYProgress);
    }
};

export const getMotionValueReverse = (
    animations: Animations[],
    scrollYProgress: number
) => {
    const inStartPoint = animations[0][2].start;
    const inEndPoint = animations[0][2].end;
    const outStartPoint = animations[1][2].start;
    const outEndPoint = animations[1][2].end;

    if (scrollYProgress < inStartPoint) {
        return;
    }
    if (scrollYProgress > outEndPoint) {
        return animations[1][1];
    }

    if (scrollYProgress > inStartPoint && scrollYProgress < inEndPoint) {
        return getOutMotionValue(animations[0], scrollYProgress);
    }
    // [0, -200, { start: 0.1, end: 0.13 }]
    if (scrollYProgress > outStartPoint && scrollYProgress <= outEndPoint) {
        const totalAnimationLength =
            animations[1][2].end - animations[1][2].start;
        const nowScrollProgress = scrollYProgress - animations[1][2].start;
        const scrollRatio = nowScrollProgress / totalAnimationLength;
        const a = 0 - Math.abs(animations[1][1]) * scrollRatio;
        return a;
    }

    return animations[0][1];
};
