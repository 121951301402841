import { motion, useViewportScroll } from "framer-motion";
import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Col, Row, SText } from "../../components";
import { COLOR } from "../../const";
import { Animations } from "../../types";
import { getMotionValue, getMotionValueReverse } from "./utils";
import introduction1 from "../../images/introduction1.jpeg";
import introduction2 from "../../images/introduction2.jpeg";
import introduction3 from "../../images/introduction3.jpeg";
import introduction4 from "../../images/intro1.mp4";
import introduction5 from "../../images/intro2.mp4";
import { IconChevronRight } from "@tabler/icons";
import { Link } from "react-router-dom";
import useLayout from "../../hooks/useLayout";
import { Helmet } from "react-helmet-async";

const MotionTest = styled(motion.div)`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  color: white;
  margin-top: 64px;
`;

const MotionTextBox = styled(motion.div)<{ isMobileWidth: boolean }>`
  position: absolute;
  opacity: 0;
  display: flex;
  padding: 0px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ isMobileWidth }) => (isMobileWidth ? "5px" : "10px")};
  z-index: 2;
`;

const BlueCircle = styled(motion.div)<{
  isMobileWidth: boolean;
  isTabletWidth: boolean;
}>`
  width: ${({ isMobileWidth, isTabletWidth }) =>
    isMobileWidth ? "75px" : isTabletWidth ? "130px" : "260px"};
  height: ${({ isMobileWidth, isTabletWidth }) =>
    isMobileWidth ? "75px" : isTabletWidth ? "130px" : "260px"};
  border-radius: ${({ isMobileWidth, isTabletWidth }) =>
    isMobileWidth ? "40px" : isTabletWidth ? "65px" : "260px"};
  background-color: #4b5feb;
  z-index: 1;
`;

const Introduction = () => {
  const { scrollY, scrollYProgress } = useViewportScroll();
  const { isMobileWidth, isTabletWidth } = useLayout();

  //비디오
  const [videoOpacity, setVideoOpacity] = useState(0);
  const videoOpacityAnimation: Animations[] = [
    [0, 0.4, { start: 0.12, end: 0.15 }],
    [0.4, 0, { start: 0.45, end: 0.46 }],
  ];
  const videoRef = useRef<HTMLVideoElement>(null);

  //BlueCircle
  const [blueOpacity1, setBlueOpacity1] = useState(0);
  const [blueBlur1, setBlueBlur1] = useState(10);
  const blueCircleOpacityAnimation: Animations[] = [
    [0, 1, { start: 0.01, end: 0.02 }],
    [1, 0, { start: 0.06, end: 0.07 }],
  ];
  const blueCircleBlurAnimation: Animations[] = [
    [10, 0, { start: 0.01, end: 0.02 }],
    [0, 10, { start: 0.06, end: 0.07 }],
  ];
  //BlueCircle2
  const [blueOpacity2, setBlueOpacity2] = useState(0);
  const [blueBlur2, setBlueBlur2] = useState(10);
  const blueCircleOpacityAnimation2: Animations[] = [
    [0, 1, { start: 0.02, end: 0.03 }],
    [1, 0, { start: 0.07, end: 0.08 }],
  ];
  const blueCircleBlurAnimation2: Animations[] = [
    [10, 0, { start: 0.02, end: 0.03 }],
    [0, 10, { start: 0.07, end: 0.08 }],
  ];
  //BlueCircle3
  const [blueOpacity3, setBlueOpacity3] = useState(0);
  const [blueBlur3, setBlueBlur3] = useState(10);
  const blueCircleOpacityAnimation3: Animations[] = [
    [0, 1, { start: 0.03, end: 0.04 }],
    [1, 0, { start: 0.08, end: 0.09 }],
  ];
  const blueCircleBlurAnimation3: Animations[] = [
    [10, 0, { start: 0.03, end: 0.04 }],
    [0, 10, { start: 0.08, end: 0.09 }],
  ];
  //BlueCircle4
  const [blueOpacity4, setBlueOpacity4] = useState(0);
  const [blueBlur4, setBlueBlur4] = useState(10);
  const blueCircleOpacityAnimation4: Animations[] = [
    [0, 1, { start: 0.04, end: 0.05 }],
    [1, 0, { start: 0.09, end: 0.1 }],
  ];
  const blueCircleBlurAnimation4: Animations[] = [
    [10, 0, { start: 0.04, end: 0.05 }],
    [0, 10, { start: 0.09, end: 0.1 }],
  ];
  //BlueCircle5
  const [blueOpacity5, setBlueOpacity5] = useState(0);
  const [blueBlur5, setBlueBlur5] = useState(10);
  const blueCircleOpacityAnimation5: Animations[] = [
    [0, 1, { start: 0.05, end: 0.06 }],
    [1, 0, { start: 0.1, end: 0.11 }],
  ];
  const blueCircleBlurAnimation5: Animations[] = [
    [10, 0, { start: 0.05, end: 0.06 }],
    [0, 10, { start: 0.1, end: 0.11 }],
  ];
  //BlueCircle6
  const [blueOpacity6, setBlueOpacity6] = useState(0);
  const [blueBlur6, setBlueBlur6] = useState(10);
  const blueCircleOpacityAnimation6: Animations[] = [
    [0, 1, { start: 0.06, end: 0.07 }],
    [1, 0, { start: 0.11, end: 0.12 }],
  ];
  const blueCircleBlurAnimation6: Animations[] = [
    [10, 0, { start: 0.06, end: 0.07 }],
    [0, 10, { start: 0.11, end: 0.12 }],
  ];
  //BlueCircle7
  const [blueOpacity7, setBlueOpacity7] = useState(0);
  const [blueBlur7, setBlueBlur7] = useState(10);
  const blueCircleOpacityAnimation7: Animations[] = [
    [0, 1, { start: 0.07, end: 0.08 }],
    [1, 0, { start: 0.12, end: 0.13 }],
  ];
  const blueCircleBlurAnimation7: Animations[] = [
    [10, 0, { start: 0.07, end: 0.08 }],
    [0, 10, { start: 0.12, end: 0.13 }],
  ];

  //글로벌 No.1
  //Web3.0 메타버스 비즈니스
  //위매드블록
  const [opacityValue, setOpacityValue] = useState(1);
  const [yValue, setYValue] = useState(0);
  const messageOpacityAnimation: Animations[] = [
    [2, 3, { start: 0.0, end: 0.0 }],
    [1, 0, { start: 0.07, end: 0.13 }],
  ];
  const messageYAnimation: Animations[] = [
    [0, 0, { start: 0.0, end: 0.0 }],
    [0, isMobileWidth ? -150 : -300, { start: 0.07, end: 0.13 }],
  ];

  //우리는 블록체인 기반의 메타버스를 통해
  //놀이가 경쟁력이 되는 세상을 만듭니다.
  const [opacityValue1, setOpacityValue1] = useState(0);
  const [yValue1, setYValue1] = useState(isMobileWidth ? 150 : 300);
  const messageOpacityAnimation1: Animations[] = [
    [0, 1, { start: 0.13, end: 0.16 }],
    [1, 0, { start: 0.2, end: 0.23 }],
  ];
  const messageYAnimation1: Animations[] = [
    [isMobileWidth ? 150 : 300, 0, { start: 0.13, end: 0.16 }],
    [0, isMobileWidth ? -150 : -300, { start: 0.2, end: 0.23 }],
  ];

  //위매드블록은 블록체인 기반의 메타버스를 만드는 기업입니다.
  //창의력이 존중받는 세상을 현실로 만들기 위해 끊임없이 노력합니다.
  const [opacityValue2, setOpacityValue2] = useState(0);
  const [yValue2, setYValue2] = useState(isMobileWidth ? 150 : 300);
  const messageOpacityAnimation2: Animations[] = [
    [0, 1, { start: 0.2, end: 0.23 }],
    [1, 0, { start: 0.27, end: 0.3 }],
  ];
  const messageYAnimation2: Animations[] = [
    [isMobileWidth ? 150 : 300, 0, { start: 0.2, end: 0.23 }],
    [0, isMobileWidth ? -150 : -300, { start: 0.27, end: 0.3 }],
  ];

  //위매드블록은 대한민국을 넘어 세계를 향합니다.
  //글로벌 No.1 Web3.0 메타버스 개발이라는 목표를 가지고
  //오픈월드 MMO RPG를 제작했던 게임 개발 전문가들과 블록체인 전문가들이 함께합니다.
  const [opacityValue3, setOpacityValue3] = useState(0);
  const [yValue3, setYValue3] = useState(isMobileWidth ? 150 : 300);
  const messageOpacityAnimation3: Animations[] = [
    [0, 1, { start: 0.27, end: 0.3 }],
    [1, 0, { start: 0.34, end: 0.37 }],
  ];
  const messageYAnimation3: Animations[] = [
    [isMobileWidth ? 150 : 300, 0, { start: 0.27, end: 0.3 }],
    [0, isMobileWidth ? -150 : -300, { start: 0.34, end: 0.37 }],
  ];

  //위매드블록은 구성원들의 복지에 진심을 다합니다.
  //모두가 즐겁고 프로답게 일할 수 있는 조직 문화를 만들어갑니다.
  const [opacityValue4, setOpacityValue4] = useState(0);
  const [yValue4, setYValue4] = useState(isMobileWidth ? 150 : 300);
  const messageOpacityAnimation4: Animations[] = [
    [0, 1, { start: 0.34, end: 0.37 }],
    [1, 0, { start: 0.41, end: 0.44 }],
  ];
  const messageYAnimation4: Animations[] = [
    [isMobileWidth ? 150 : 300, 0, { start: 0.34, end: 0.37 }],
    [0, isMobileWidth ? -150 : -300, { start: 0.41, end: 0.44 }],
  ];

  useEffect(() => {
    scrollYProgress.onChange((v) => {
      if (videoRef && v > 0.1 && v < 0.48) {
        videoRef.current?.play();
      }
      if (videoRef && v > 0.48) {
        videoRef.current?.pause();
      }

      setBlueOpacity1(getMotionValue(blueCircleOpacityAnimation, v)!);
      setBlueBlur1(
        Math.abs(getMotionValueReverse(blueCircleBlurAnimation, v)!)
      );
      setBlueOpacity2(getMotionValue(blueCircleOpacityAnimation2, v)!);
      setBlueBlur2(
        Math.abs(getMotionValueReverse(blueCircleBlurAnimation2, v)!)
      );
      setBlueOpacity3(getMotionValue(blueCircleOpacityAnimation3, v)!);
      setBlueBlur3(
        Math.abs(getMotionValueReverse(blueCircleBlurAnimation3, v)!)
      );
      setBlueOpacity4(getMotionValue(blueCircleOpacityAnimation4, v)!);
      setBlueBlur4(
        Math.abs(getMotionValueReverse(blueCircleBlurAnimation4, v)!)
      );
      setBlueOpacity5(getMotionValue(blueCircleOpacityAnimation5, v)!);
      setBlueBlur5(
        Math.abs(getMotionValueReverse(blueCircleBlurAnimation5, v)!)
      );
      setBlueOpacity6(getMotionValue(blueCircleOpacityAnimation6, v)!);
      setBlueBlur6(
        Math.abs(getMotionValueReverse(blueCircleBlurAnimation6, v)!)
      );
      setBlueOpacity7(getMotionValue(blueCircleOpacityAnimation7, v)!);
      setBlueBlur7(
        Math.abs(getMotionValueReverse(blueCircleBlurAnimation7, v)!)
      );

      setVideoOpacity(getMotionValue(videoOpacityAnimation, v)!);

      setOpacityValue(getMotionValue(messageOpacityAnimation, v)!);
      setYValue(getMotionValueReverse(messageYAnimation, v)!);

      setOpacityValue1(getMotionValue(messageOpacityAnimation1, v)!);
      setYValue1(getMotionValueReverse(messageYAnimation1, v)!);

      setOpacityValue2(getMotionValue(messageOpacityAnimation2, v)!);
      setYValue2(getMotionValueReverse(messageYAnimation2, v)!);

      setOpacityValue3(getMotionValue(messageOpacityAnimation3, v)!);
      setYValue3(getMotionValueReverse(messageYAnimation3, v)!);

      setOpacityValue4(getMotionValue(messageOpacityAnimation4, v)!);
      setYValue4(getMotionValueReverse(messageYAnimation4, v)!);
    });
  }, [scrollY, scrollYProgress]);

  return (
    <>
      <Helmet>
        <title>회사소개 - 위매드블록</title>
      </Helmet>
      <Col style={{ alignItems: "center", overflow: "hidden" }}>
        <Col
          style={{
            height: `${
              isMobileWidth ? "2800px" : isTabletWidth ? "3800px" : "6800px"
            }`,
          }}
        >
          <MotionTest
            style={{
              marginTop: "0px",
            }}
          >
            <motion.video
              initial={{ opacity: 0 }}
              animate={{
                opacity: videoOpacity,
                transition: { type: "tween" },
              }}
              ref={videoRef}
              src={isTabletWidth ? introduction5 : introduction4}
              playsInline
              muted
              loop
              style={{
                minHeight: `${isTabletWidth ? "100vh" : "120vh"}`,
              }}
            />
            <MotionTextBox isMobileWidth={isMobileWidth} style={{ opacity: 1 }}>
              <Row
                style={{
                  width: "100vw",
                  height: "100vh",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Col>
                  <BlueCircle
                    isMobileWidth={isMobileWidth}
                    isTabletWidth={isTabletWidth}
                    initial={{
                      opacity: 0,
                      filter: `blur(${blueBlur1}px)`,
                    }}
                    animate={{
                      opacity: blueOpacity1,
                      filter: `blur(${blueBlur1}px)`,
                    }}
                  />
                  <BlueCircle
                    isMobileWidth={isMobileWidth}
                    isTabletWidth={isTabletWidth}
                    initial={{
                      opacity: 0,
                      filter: `blur(${blueBlur2}px)`,
                    }}
                    animate={{
                      opacity: blueOpacity2,
                      filter: `blur(${blueBlur2}px)`,
                    }}
                    style={{ marginTop: "28px" }}
                  />
                </Col>
                <Col>
                  <BlueCircle
                    isMobileWidth={isMobileWidth}
                    isTabletWidth={isTabletWidth}
                    style={{ opacity: 0 }}
                  />
                  <BlueCircle
                    isMobileWidth={isMobileWidth}
                    isTabletWidth={isTabletWidth}
                    initial={{
                      opacity: 0,
                      filter: `blur(${blueBlur3}px)`,
                    }}
                    animate={{
                      opacity: blueOpacity3,
                      filter: `blur(${blueBlur3}px)`,
                    }}
                    style={{
                      marginTop: `${isMobileWidth ? "187px" : "287px"}`,
                    }}
                  />
                </Col>
                <Col>
                  <BlueCircle
                    isMobileWidth={isMobileWidth}
                    isTabletWidth={isTabletWidth}
                    style={{ opacity: 0 }}
                  />
                  <BlueCircle
                    isMobileWidth={isMobileWidth}
                    isTabletWidth={isTabletWidth}
                    initial={{
                      opacity: 0,
                      filter: `blur(${blueBlur4}px)`,
                    }}
                    animate={{
                      opacity: blueOpacity4,
                      filter: `blur(${blueBlur4}px)`,
                    }}
                    style={{ marginTop: "28px" }}
                  />
                </Col>
                <Col>
                  <BlueCircle
                    isMobileWidth={isMobileWidth}
                    isTabletWidth={isTabletWidth}
                    style={{ opacity: 0 }}
                  />
                  <BlueCircle
                    isMobileWidth={isMobileWidth}
                    isTabletWidth={isTabletWidth}
                    initial={{
                      opacity: 0,
                      filter: `blur(${blueBlur5}px)`,
                    }}
                    animate={{
                      opacity: blueOpacity5,
                      filter: `blur(${blueBlur5}px)`,
                    }}
                    style={{
                      marginTop: `${isMobileWidth ? "187px" : "287px"}`,
                    }}
                  />
                </Col>
                <Col>
                  <BlueCircle
                    isMobileWidth={isMobileWidth}
                    isTabletWidth={isTabletWidth}
                    initial={{
                      opacity: 0,
                      filter: `blur(${blueBlur7}px)`,
                    }}
                    animate={{
                      opacity: blueOpacity7,
                      filter: `blur(${blueBlur7}px)`,
                    }}
                  />
                  <BlueCircle
                    isMobileWidth={isMobileWidth}
                    isTabletWidth={isTabletWidth}
                    initial={{
                      opacity: 0,
                      filter: `blur(${blueBlur6}px)`,
                    }}
                    animate={{
                      opacity: blueOpacity6,
                      filter: `blur(${blueBlur6}px)`,
                    }}
                    style={{ marginTop: "28px" }}
                  />
                </Col>
              </Row>
            </MotionTextBox>

            <MotionTextBox
              isMobileWidth={isMobileWidth}
              initial={{
                y: yValue,
                opacity: opacityValue,
              }}
              animate={{
                opacity: opacityValue ? opacityValue : 0,
                y: yValue,
                transition: { type: "tween" },
              }}
              style={{ gap: "0px" }}
            >
              <SText
                fontType={isMobileWidth ? "M.20" : "M.26"}
                color={COLOR.textColor.white}
                children="글로벌 No.1"
              />
              <SText
                fontType={isMobileWidth ? "M.20" : "M.26"}
                style={{ marginTop: "0.3em" }}
                color={COLOR.textColor.white}
                children="Web3.0 메타버스 비즈니스"
              />
              <SText
                fontType={isMobileWidth ? "SB.74" : "SB.140"}
                color={COLOR.textColor.white}
                children="위매드블록"
              />
            </MotionTextBox>

            <MotionTextBox
              isMobileWidth={isMobileWidth}
              initial={{ y: yValue1 }}
              animate={{
                opacity: opacityValue1,
                y: yValue1,
                transition: { type: "tween" },
              }}
            >
              <SText
                fontType={
                  isMobileWidth ? "SB.18" : isTabletWidth ? "SB.26" : "SB.60"
                }
                children="우리는 블록체인 기반의 메타버스를 통해"
              />
              <SText
                fontType={
                  isMobileWidth ? "SB.22" : isTabletWidth ? "SB.40" : "SB.80"
                }
                children="놀이가 경쟁력이 되는 세상을 만듭니다."
              />
            </MotionTextBox>

            <MotionTextBox
              isMobileWidth={isMobileWidth}
              initial={{ y: yValue2 }}
              animate={{
                opacity: opacityValue2,
                y: yValue2,
                transition: { type: "tween" },
              }}
            >
              <SText
                fontType={
                  isMobileWidth ? "SB.18" : isTabletWidth ? "M.20" : "M.40"
                }
                style={{ textAlign: "center" }}
                children={
                  isTabletWidth
                    ? "우리는 블록체인 기반의 메타버스를 통해"
                    : "위매드블록은 블록체인 기반의 메타버스를 만드는 기업입니다."
                }
              />
              <SText
                fontType={
                  isMobileWidth ? "SB.22" : isTabletWidth ? "M.20" : "M.40"
                }
                style={{ textAlign: "center" }}
                children="창의력이 존중받는 세상을 현실로 만들기 위해 끊임없이 노력합니다."
              />
            </MotionTextBox>

            <MotionTextBox
              isMobileWidth={isMobileWidth}
              initial={{ y: yValue3 }}
              animate={{
                opacity: opacityValue3,
                y: yValue3,
                transition: { type: "tween" },
              }}
            >
              <SText
                fontType={
                  isMobileWidth ? "SB.18" : isTabletWidth ? "M.20" : "M.40"
                }
                children={
                  isTabletWidth
                    ? "대한민국을 넘어 세계를 향합니다."
                    : "위매드블록은 대한민국을 넘어 세계를 향합니다."
                }
              />
              <SText
                fontType={
                  isMobileWidth ? "SB.20" : isTabletWidth ? "M.20" : "M.40"
                }
                children={
                  isTabletWidth
                    ? "No.1 Web3.0 메타버스라는 목표를 가지고"
                    : "글로벌 No.1 Web3.0 메타버스 개발이라는 목표를 가지고"
                }
              />
              <SText
                fontType={
                  isMobileWidth ? "SB.22" : isTabletWidth ? "M.20" : "M.40"
                }
                style={{ textAlign: "center" }}
                children={
                  isTabletWidth
                    ? "오픈월드 게임 개발 전문가들과 블록체인 전문가들이 함께합니다."
                    : "오픈월드 MMO RPG를 제작했던 게임 개발 전문가들과 블록체인 전문가들이 함께합니다."
                }
              />
            </MotionTextBox>

            <MotionTextBox
              isMobileWidth={isMobileWidth}
              initial={{ y: yValue4 }}
              animate={{
                opacity: opacityValue4,
                y: yValue4,
                transition: { type: "tween" },
              }}
            >
              <SText
                fontType={
                  isMobileWidth ? "SB.18" : isTabletWidth ? "M.20" : "M.40"
                }
                children={
                  isTabletWidth
                    ? "구성원들의 복지에 진심을 다합니다."
                    : "위매드블록은 구성원들의 복지에 진심을 다합니다."
                }
              />
              <SText
                fontType={
                  isMobileWidth ? "SB.22" : isTabletWidth ? "M.20" : "M.40"
                }
                style={{ textAlign: "center" }}
                children="모두가 즐겁고 프로답게 일할 수 있는 조직 문화를 만들어갑니다."
              />
            </MotionTextBox>
          </MotionTest>
        </Col>
        <Col
          style={{
            maxWidth: `${isTabletWidth ? "100vw" : "1920px"}`,
            padding: `${
              isMobileWidth
                ? "0px 20px"
                : isTabletWidth
                ? "0px 50px"
                : "0px 220px"
            }`,
            flex: "1 1 auto",
          }}
        >
          <motion.div
            // initial={{ opacity: 0, y: 20 }}
            initial={{ opacity: 1, y: 0 }}
            // whileInView={{
            //     opacity: 1,
            //     y: 0,
            //     transition: {
            //         delay: 0.7,
            //         duration: 1,
            //         type: "tween",
            //     },
            // }}
          >
            <SText
              fontType={isTabletWidth ? "SB.40" : "SB.80"}
              color={COLOR.textColor.white}
              children="숫자로 보는"
            />
            <SText
              fontType={isTabletWidth ? "SB.40" : "SB.80"}
              color={COLOR.textColor.white}
              children="WMB의 가능성"
            />
          </motion.div>
          <Col style={{ marginTop: "50px" }}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 1,
                  duration: 1,
                  type: "tween",
                },
              }}
            >
              <Row
                style={{
                  flexDirection: `${isMobileWidth ? "column" : "row"}`,
                }}
              >
                <SText
                  fontType={
                    isMobileWidth ? "M.13" : isTabletWidth ? "M.16" : "M.32"
                  }
                  color={COLOR.textColor.gray}
                  children="위매드블록은 2022년에 설립되어 업계에서 큰 인정을 받으며 "
                />
                <SText
                  fontType={
                    isMobileWidth ? "M.13" : isTabletWidth ? "M.16" : "M.32"
                  }
                  color={COLOR.textColor.gray}
                  style={{
                    marginLeft: `${isMobileWidth ? "0px" : "8px"}`,
                  }}
                  children="빠르게 성장하고 있습니다."
                />
              </Row>
              <Row
                style={{
                  marginTop: "1rem",
                  flexDirection: `${isMobileWidth ? "column" : "row"}`,
                }}
              >
                <SText
                  fontType={
                    isMobileWidth ? "M.13" : isTabletWidth ? "M.16" : "M.32"
                  }
                  color={COLOR.textColor.gray}
                  children="우리가 첫 서비스를 선보인 지 1년도 채 안 된 시점에서 "
                />
                <Row>
                  <motion.div
                    initial={{
                      opacity: 1,
                      color: `${COLOR.textColor.gray}`,
                    }}
                    whileInView={{
                      opacity: 1,
                      color: `${COLOR.textColor.white}`,
                      transition: {
                        delay: 1,
                        duration: 1,
                        type: "tween",
                      },
                    }}
                  >
                    <SText
                      fontType={
                        isMobileWidth ? "M.13" : isTabletWidth ? "M.16" : "M.32"
                      }
                      style={{
                        marginLeft: `${
                          isMobileWidth
                            ? "0rem"
                            : isTabletWidth
                            ? "5px"
                            : "1rem"
                        }`,
                      }}
                      children="상반기 매출 180억 원"
                    />
                  </motion.div>

                  <SText
                    fontType={
                      isMobileWidth ? "M.13" : isTabletWidth ? "M.16" : "M.32"
                    }
                    color={COLOR.textColor.gray}
                    children="을 이뤘으며,"
                  />
                </Row>
              </Row>
              {!isMobileWidth && (
                <Row style={{ marginTop: "1rem" }}>
                  <SText
                    fontType={isTabletWidth ? "M.16" : "M.32"}
                    color={COLOR.textColor.gray}
                    children={
                      "10명에서 50명까지 구성원의 규모는 팽창하고 있습니다."
                    }
                  />
                </Row>
              )}
              <Row
                style={{
                  marginTop: "1rem",
                  flexDirection: `${isMobileWidth ? "column" : "row"}`,
                }}
              >
                <SText
                  fontType={
                    isMobileWidth ? "M.13" : isTabletWidth ? "M.16" : "M.32"
                  }
                  color={COLOR.textColor.gray}
                  children={
                    isMobileWidth
                      ? "10명에서 50명까지 구성원의 규모는 팽창하고 있습니다."
                      : "이러한 WMB의 가치는 "
                  }
                />
                <Row>
                  {isMobileWidth && (
                    <SText
                      fontType="M.13"
                      color={COLOR.textColor.gray}
                      children="이러한 WMB의 가치는"
                    />
                  )}
                  <motion.div
                    initial={{
                      opacity: 1,
                      color: `${COLOR.textColor.gray}`,
                    }}
                    whileInView={{
                      opacity: 1,
                      color: `${COLOR.textColor.white}`,
                      transition: {
                        delay: 1.5,
                        duration: 1,
                        type: "tween",
                      },
                    }}
                  >
                    <SText
                      fontType={
                        isMobileWidth ? "M.13" : isTabletWidth ? "M.16" : "M.32"
                      }
                      style={{
                        marginLeft: `${
                          isMobileWidth
                            ? "0rem"
                            : isTabletWidth
                            ? "5px"
                            : "1rem"
                        }`,
                      }}
                      children=" 150억 원의 대규모 투자 유치"
                    />
                  </motion.div>
                </Row>
                <SText
                  fontType={
                    isMobileWidth ? "M.13" : isTabletWidth ? "M.16" : "M.32"
                  }
                  color={COLOR.textColor.gray}
                  children="를 가능하게 했죠."
                />
              </Row>
              <Row
                style={{
                  marginTop: "1rem",
                  flexDirection: `${isMobileWidth ? "column" : "row"}`,
                }}
              >
                {!isMobileWidth && (
                  <SText
                    fontType={
                      isMobileWidth ? "M.13" : isTabletWidth ? "M.16" : "M.32"
                    }
                    color={COLOR.textColor.gray}
                    children="믿을 수 없는"
                  />
                )}
                <motion.div
                  initial={{
                    opacity: 1,
                    color: `${COLOR.textColor.gray}`,
                  }}
                  whileInView={{
                    opacity: 1,
                    color: `${COLOR.textColor.white}`,
                    transition: {
                      delay: 2,
                      duration: 1,
                      type: "tween",
                    },
                  }}
                >
                  <SText
                    fontType={
                      isMobileWidth ? "M.13" : isTabletWidth ? "M.16" : "M.32"
                    }
                    style={{
                      marginLeft: `${
                        isMobileWidth ? "0rem" : isTabletWidth ? "5px" : "1rem"
                      }`,
                    }}
                    children="우리의 성장, 이건 아직 시작에 불과합니다."
                  />
                </motion.div>
              </Row>
            </motion.div>
          </Col>

          <Col
            style={{
              alignItems: "center",
              marginTop: `${isTabletWidth ? "150px" : "300px"}`,
              marginBottom: `${isTabletWidth ? "150px" : "300px"}`,
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 1,
                  duration: 1,
                  type: "tween",
                },
              }}
            >
              <SText
                fontType={
                  isMobileWidth ? "SB.48" : isTabletWidth ? "SB.80" : "SB.180"
                }
                color={COLOR.primaryColor}
                children="15,000,000,000"
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.5,
                  duration: 1,
                  type: "tween",
                },
              }}
            >
              <SText
                fontType={isTabletWidth ? "M.18" : "M.30"}
                color={COLOR.textColor.white}
                style={{
                  marginTop: `${isTabletWidth ? "10px" : "30px"}`,
                }}
                children="22년 대규모 투자 유치"
              />
            </motion.div>
          </Col>
        </Col>
        {/* 채용 그래프 들어갈 곳 */}

        <motion.div
          initial={{ opacity: 0, y: 40 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.7, duration: 1, type: "tween" },
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "1920px",
            width: "100vw",
            position: "relative",
            marginBottom: `${isTabletWidth ? "100px" : "300px"}`,
          }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              height: "0px",
              paddingBottom: `${isMobileWidth ? "60.2%" : "45.2%"}`,
              // paddingBottom: "60.2%",
              // paddingBottom: "45.2%",
              backgroundImage: `url(${introduction3})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <img
              src={introduction2}
              alt="hireing"
              style={{
                display: "none",
              }}
            />
          </Row>
          <Row style={{ justifyContent: "center", marginTop: "30px" }}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.1,
                  duration: 1,
                  type: "tween",
                },
              }}
            >
              <SText
                fontType={
                  isMobileWidth ? "M.13" : isTabletWidth ? "M.16" : "M.32"
                }
                color={COLOR.textColor.white}
                children="2022년 위매드블록 규모"
              />
            </motion.div>
          </Row>
        </motion.div>

        <Col
          style={{
            maxWidth: "1920px",
            width: "100vw",
            height: `${isTabletWidth ? "50vh" : "1080px"}`,
          }}
        >
          <Col
            style={{
              justifyContent: "center",
              alignItems: "center",
              flex: "1 1 auto",
            }}
          >
            <Row style={{ alignItems: "baseline" }}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.2,
                    duration: 1,
                    type: "tween",
                  },
                }}
              >
                <SText
                  fontType={
                    isMobileWidth ? "SB.48" : isTabletWidth ? "SB.80" : "SB.180"
                  }
                  color={COLOR.primaryColor}
                  children="18,000,000,000 "
                />
              </motion.div>
            </Row>
            <Col style={{ alignItems: "center" }}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.4,
                    duration: 1,
                    type: "tween",
                  },
                }}
              >
                <SText
                  fontType={isTabletWidth ? "M.20" : "M.30"}
                  style={{
                    marginTop: `${isTabletWidth ? "10px" : "30px"}`,
                  }}
                  color={COLOR.textColor.white}
                  children="22년 상반기 총 매출"
                />
              </motion.div>
            </Col>
          </Col>
        </Col>
        <Col
          style={{
            width: "100vw",
            maxWidth: "1920px",
            position: "relative",
          }}
        >
          <Col
            style={{
              flex: "1 1 auto",
              position: "absolute",
              width: "100vw",
              maxWidth: "1920px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SText
              fontType={
                isMobileWidth ? "SB.16" : isTabletWidth ? "SB.40" : "SB.80"
              }
              color={COLOR.textColor.white}
              style={{ marginTop: "20%" }}
              children="우리가 창의력을 표현하는 방식"
            />
            <Link to="/culture" style={{ cursor: "pointer" }}>
              <Row
                style={{
                  marginTop: `${isMobileWidth ? "5px" : "30px"}`,
                  alignItems: "center",
                }}
              >
                <SText
                  fontType={
                    isMobileWidth ? "M.10" : isTabletWidth ? "M.20" : "M.30"
                  }
                  color={COLOR.textColor.white}
                  children="WMB Culture 알아보기"
                />
                <IconChevronRight
                  color={COLOR.textColor.white}
                  width={isMobileWidth ? 15 : 30}
                  height={isMobileWidth ? 15 : 30}
                  style={{ marginLeft: "5px" }}
                />
              </Row>
            </Link>
          </Col>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",

              height: "0px",
              paddingBottom: "56.3%",
              backgroundImage: `url(${introduction1})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <img
              src={introduction1}
              alt="hireing"
              style={{
                display: "none",
              }}
            />
          </Row>
        </Col>

        <Col
          style={{
            width: "100vw",
            maxWidth: "1920px",
            position: "relative",
          }}
        >
          <Link to="/hireing" style={{ cursor: "pointer" }}>
            {isMobileWidth ? (
              <Col
                style={{
                  flex: "1 1 auto",
                  position: "absolute",
                  width: "100vw",
                  maxWidth: "1920px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SText
                  fontType={
                    isMobileWidth ? "SB.16" : isTabletWidth ? "SB.40" : "SB.80"
                  }
                  color={COLOR.textColor.white}
                  style={{ marginTop: "20%" }}
                  children="함께 성장해요. 이루고 즐기면서"
                />
                <Link to="/hireing" style={{ cursor: "pointer" }}>
                  <Row
                    style={{
                      marginTop: `${isMobileWidth ? "5px" : "30px"}`,
                      alignItems: "center",
                    }}
                  >
                    <SText
                      fontType={
                        isMobileWidth ? "M.10" : isTabletWidth ? "M.20" : "M.30"
                      }
                      color={COLOR.textColor.white}
                      children="채용공고 알아보기"
                    />
                    <IconChevronRight
                      color={COLOR.textColor.white}
                      width={isMobileWidth ? 15 : 30}
                      height={isMobileWidth ? 15 : 30}
                      style={{ marginLeft: "5px" }}
                    />
                  </Row>
                </Link>
              </Col>
            ) : (
              <Col
                style={{
                  flex: "1 1 auto",
                  position: "absolute",
                  justifyContent: "center",
                  marginTop: "16%",
                  marginLeft: `${isTabletWidth ? "100px" : "220px"}`,
                }}
              >
                <SText
                  fontType={
                    isMobileWidth ? "SB.26" : isTabletWidth ? "SB.40" : "SB.80"
                  }
                  color={COLOR.textColor.white}
                  children="함께 성장해요."
                />
                <SText
                  fontType={
                    isMobileWidth ? "SB.26" : isTabletWidth ? "SB.40" : "SB.80"
                  }
                  color={COLOR.textColor.white}
                  children="이루고"
                />
                <SText
                  fontType={
                    isMobileWidth ? "SB.26" : isTabletWidth ? "SB.40" : "SB.80"
                  }
                  color={COLOR.textColor.white}
                  children="즐기면서."
                />
                <Row
                  style={{
                    marginTop: "40px",
                    alignItems: "center",
                  }}
                >
                  <SText
                    fontType={isTabletWidth ? "M.20" : "M.30"}
                    color={COLOR.textColor.white}
                    children="채용공고 알아보기"
                  />
                  <IconChevronRight
                    color={COLOR.textColor.white}
                    width={30}
                    height={30}
                    style={{ marginLeft: "5px" }}
                  />
                </Row>
              </Col>
            )}
          </Link>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              height: "0px",
              paddingBottom: "50%",
              backgroundImage: `url(${introduction2})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <img
              src={introduction2}
              alt="hireing"
              style={{
                display: "none",
              }}
            />
          </Row>
        </Col>
      </Col>
    </>
  );
};

export default Introduction;
