const primaryColor = "#D2FA64";
const textColor = {
    gray: "#7D7D7D",
    white: "#FAFAF5",
};
const borderColor = "#646464";
const bgColor = "#191919";

//eslint-disable-next-line
export default { primaryColor, textColor, borderColor, bgColor };
