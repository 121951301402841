import { HTMLAttributes } from "react";
import Col from "./Col";

export type FontType =
  // Regular
  | "R.15"
  | "R.12"
  // Medium
  | "M.54"
  | "M.40"
  | "M.32"
  | "M.30"
  | "M.27"
  | "M.26"
  | "M.25"
  | "M.23"
  | "M.20"
  | "M.18"
  | "M.16"
  | "M.15"
  | "M.14"
  | "M.13"
  | "M.12"
  | "M.10"
  // SemiBold
  | "SB.180"
  | "SB.140"
  | "SB.80"
  | "SB.74"
  | "SB.64"
  | "SB.60"
  | "SB.53"
  | "SB.48"
  | "SB.40"
  | "SB.32"
  | "SB.34"
  | "SB.26"
  | "SB.25"
  | "SB.24"
  | "SB.22"
  | "SB.20"
  | "SB.18"
  | "SB.16"
  | "SB.15"
  | "SB.13"
  | "SB.14"
  | "SB.12"
  | "SB.10";

interface STextProps extends HTMLAttributes<HTMLDivElement> {
  fontType: FontType;
}

const getFontStyle = (fontType: FontType) => {
  const weightType = fontType.split(".")[0];
  const fontSize = `${fontType.split(".")[1]}px`;

  let fontWeight = 400;
  if (weightType === "M") {
    fontWeight = 500;
  } else if (weightType === "SB") {
    fontWeight = 600;
  } else if (weightType === "B") {
    fontWeight = 700;
  }

  return {
    fontSize,
    fontWeight,
  };
};

const SText = ({ fontType, style, children, color, onClick }: STextProps) => {
  const fontStyle = getFontStyle(fontType);

  return (
    <Col
      style={{ color, ...fontStyle, ...style }}
      children={children}
      onClick={onClick}
    />
  );
};

export default SText;
