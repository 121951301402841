import styled from "styled-components";
import { Col, Row, SText } from "../../components";
import { COLOR } from "../../const";
import hireingImg from "../../images/hireing.jpeg";
import useLayout from "../../hooks/useLayout";
import { Helmet } from "react-helmet-async";

const HireingContainer = styled(Row)`
    width: 100%;
    flex: 0 2 auto;
    justify-content: center;
    color: ${COLOR.textColor.white}; ;
`;

const HireingContainerSub = styled(Col)<{ isTabletWidth: boolean }>`
    flex: 1 1 auto;
    max-width: 1920px;
    padding: ${({ isTabletWidth }) =>
        isTabletWidth ? "84px 30px" : "84px 220px"};
    margin-top: 64px;
`;

const Section = styled.section<{ isTabletWidth: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${({ isTabletWidth }) => (isTabletWidth ? "100%" : "50%")};
    margin-top: 60px;
`;

const MainSectionContainer = styled(Row)`
    flex-wrap: wrap;
    justify-content: space-between;
`;

const FlexibleImgWrapper = styled(Row)`
    margin-top: 60px;
    height: 0px;
    padding-bottom: 56%;
    background-image: url(${hireingImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

const Hireing = () => {
    const { isTabletWidth, isMobileWidth } = useLayout();

    return (
        <>
            <Helmet>
                <title>채용공고 - 위매드블록</title>
            </Helmet>
            <HireingContainer>
                <HireingContainerSub isTabletWidth={isTabletWidth}>
                    <h2>
                        <SText
                            fontType={isTabletWidth ? "SB.14" : "SB.24"}
                            color={COLOR.primaryColor}
                            children="채용공고"
                        />
                    </h2>
                    <h3>
                        <SText
                            fontType={
                                isMobileWidth
                                    ? "SB.26"
                                    : isTabletWidth
                                    ? "SB.34"
                                    : "SB.64"
                            }
                            style={{ marginTop: "15px" }}
                            children="우리가 일하는 방식"
                        />
                    </h3>
                    {/* 이미지 넣을 곳 */}
                    <FlexibleImgWrapper>
                        <img
                            src={hireingImg}
                            alt="hireing"
                            style={{
                                display: "none",
                            }}
                        />
                    </FlexibleImgWrapper>
                    <h3>
                        <SText
                            fontType={
                                isMobileWidth
                                    ? "M.23"
                                    : isTabletWidth
                                    ? "M.27"
                                    : "M.54"
                            }
                            style={{ marginTop: "50px" }}
                            children="함께 즐기며 성장할"
                        />
                        <SText
                            fontType={
                                isMobileWidth
                                    ? "M.23"
                                    : isTabletWidth
                                    ? "M.27"
                                    : "M.54"
                            }
                            style={{
                                marginTop: `${isMobileWidth ? "5px" : "15px"}`,
                            }}
                            children="인재를 찾고 있습니다."
                        />
                    </h3>
                    <Row
                        style={{
                            flex: "1 1 auto",
                            height: "1px",
                            backgroundColor: `${COLOR.borderColor}`,
                            marginTop: "60px",
                        }}
                    />
                    <main style={{ display: "flex", flexDirection: "column" }}>
                        <MainSectionContainer>
                            <Section isTabletWidth={isTabletWidth}>
                                <h4>
                                    <SText
                                        fontType={
                                            isTabletWidth ? "SB.22" : "SB.32"
                                        }
                                        color={COLOR.primaryColor}
                                        children="Dive into the blockchain!"
                                    />
                                </h4>
                                <p>
                                    <Col style={{ marginTop: "20px" }}>
                                        {!isMobileWidth ? (
                                            <>
                                                <SText
                                                    fontType={
                                                        isTabletWidth
                                                            ? "M.14"
                                                            : "M.20"
                                                    }
                                                    children="블록체인 산업은 최근 가장 빠르게 성장하고 있는 산업 중 하나입니다."
                                                />
                                                <SText
                                                    fontType={
                                                        isTabletWidth
                                                            ? "M.14"
                                                            : "M.20"
                                                    }
                                                    style={{ marginTop: "7px" }}
                                                    children="위매드블록 구성원들은 모두가 미래의 블록체인 전문가라는"
                                                />
                                                <SText
                                                    fontType={
                                                        isTabletWidth
                                                            ? "M.14"
                                                            : "M.20"
                                                    }
                                                    style={{ marginTop: "7px" }}
                                                    children="마음가짐으로 주어진 일에 최선을 다합니다."
                                                />
                                            </>
                                        ) : (
                                            <SText
                                                fontType="M.14"
                                                children="블록체인 산업은 최근 가장 빠르게 성장하고 있는 산업 중 하나입니다. 
                                    위매드블록 구성원들은 모두가 미래의 블록체인 전문가라는
                                    마음가짐으로 주어진 일에 최선을 다합니다.
                                    "
                                            />
                                        )}
                                    </Col>
                                </p>
                            </Section>

                            <Section isTabletWidth={isTabletWidth}>
                                <h4>
                                    <SText
                                        fontType={
                                            isTabletWidth ? "SB.22" : "SB.32"
                                        }
                                        color={COLOR.primaryColor}
                                        children="자가발전"
                                    />
                                </h4>
                                <p>
                                    <Col style={{ marginTop: "20px" }}>
                                        {!isMobileWidth ? (
                                            <>
                                                <SText
                                                    fontType={
                                                        isTabletWidth
                                                            ? "M.14"
                                                            : "M.20"
                                                    }
                                                    children="누구나 일을 하다 보면 때때로 난관에 봉착하곤 합니다."
                                                />
                                                <SText
                                                    fontType={
                                                        isTabletWidth
                                                            ? "M.14"
                                                            : "M.20"
                                                    }
                                                    style={{ marginTop: "7px" }}
                                                    children="위매드블록 구성원은 열정을 토대로 목표 달성을 위한 방법을 스스로 찾으며,"
                                                />
                                                <SText
                                                    fontType={
                                                        isTabletWidth
                                                            ? "M.14"
                                                            : "M.20"
                                                    }
                                                    style={{ marginTop: "7px" }}
                                                    children="더 나아가 실무자의 경험과 결정으로 Bottom-up의 결과를 이루어냅니다."
                                                />
                                            </>
                                        ) : (
                                            <SText
                                                fontType="M.14"
                                                children="누구나 일을 하다 보면 때때로 난관에 봉착하곤 합니다.
                                    위매드블록 구성원은 열정을 토대로 목표 달성을 위한 방법을 스스로 찾으며,
                                    더 나아가 실무자의 경험과 결정으로 Bottom-up의 결과를 이루어 냅니다.
                                    "
                                            />
                                        )}
                                    </Col>
                                </p>
                            </Section>
                        </MainSectionContainer>

                        <MainSectionContainer>
                            <Section isTabletWidth={isTabletWidth}>
                                <h4>
                                    <SText
                                        fontType={
                                            isTabletWidth ? "SB.22" : "SB.32"
                                        }
                                        color={COLOR.primaryColor}
                                        children="열정과 문제해결능력"
                                    />
                                </h4>
                                <p>
                                    <Col style={{ marginTop: "20px" }}>
                                        {!isMobileWidth ? (
                                            <>
                                                <SText
                                                    fontType={
                                                        isTabletWidth
                                                            ? "M.14"
                                                            : "M.20"
                                                    }
                                                    children="새로운 기술을 익히고 새로운 트렌드를 파악하여 끊임없이 변화하는 환경에"
                                                />
                                                <SText
                                                    fontType={
                                                        isTabletWidth
                                                            ? "M.14"
                                                            : "M.20"
                                                    }
                                                    style={{ marginTop: "7px" }}
                                                    children="적응하며 일합니다. Order가 아닌 Role에 의지하며, 본인의 Role을 확고히"
                                                />
                                                <SText
                                                    fontType={
                                                        isTabletWidth
                                                            ? "M.14"
                                                            : "M.20"
                                                    }
                                                    style={{ marginTop: "7px" }}
                                                    children="하기 위해 새로운 기술과 역량을 터득하는데 주저하지 않습니다."
                                                />
                                            </>
                                        ) : (
                                            <SText
                                                fontType="M.14"
                                                children="새로운 기술을 익히고 새로운 트렌드를 파악하여 끊임없이 변화하는 환경에
                                    적응하며 일합니다. Order가 아닌 Role에 의지하며, 본인의 Role을 확고히
                                    하기 위해 새로운 기술과 역량을 터득하는데 주저하지 않습니다.
                                    "
                                            />
                                        )}
                                    </Col>
                                </p>
                            </Section>

                            <Section isTabletWidth={isTabletWidth}>
                                <h4>
                                    <SText
                                        fontType={
                                            isTabletWidth ? "SB.22" : "SB.32"
                                        }
                                        color={COLOR.primaryColor}
                                        children="즐기면서 일하는 프로"
                                    />
                                </h4>
                                <p>
                                    <Col style={{ marginTop: "20px" }}>
                                        {!isMobileWidth ? (
                                            <>
                                                <SText
                                                    fontType={
                                                        isTabletWidth
                                                            ? "M.14"
                                                            : "M.20"
                                                    }
                                                    children="천재는 노력하는 사람을 이길 수 없고 노력하는 사람은 즐기는 사람을 이길 수"
                                                />
                                                <SText
                                                    fontType={
                                                        isTabletWidth
                                                            ? "M.14"
                                                            : "M.20"
                                                    }
                                                    style={{ marginTop: "7px" }}
                                                    children="없다고 합니다. 위매드블록 구성원은 모두가 즐기면서 일을 하고 엣지 있는"
                                                />
                                                <SText
                                                    fontType={
                                                        isTabletWidth
                                                            ? "M.14"
                                                            : "M.20"
                                                    }
                                                    style={{ marginTop: "7px" }}
                                                    children="결과물을 만들어 냅니다."
                                                />
                                            </>
                                        ) : (
                                            <SText
                                                fontType={
                                                    isTabletWidth
                                                        ? "M.14"
                                                        : "M.20"
                                                }
                                                children="천재는 노력하는 사람을 이길 수 없고 노력하는 사람은 즐기는 사람을 이길 수 
                                없다고 합니다. 위매드블록 구성원은 모두가 즐기면서 일을 하고 엣지 있는
                                결과물을 만들어 냅니다."
                                            />
                                        )}
                                    </Col>
                                </p>
                            </Section>
                        </MainSectionContainer>
                    </main>

                    <Row
                        style={{
                            justifyContent: "center",
                            marginTop: "150px",
                            marginBottom: `${isTabletWidth ? "60px" : "300px"}`,
                        }}
                    >
                        <Row
                            onClick={() =>
                                window.open(
                                    "https://great-puppet-3df.notion.site/WMB-4afda71b6a8f43cc80199426a928c182",
                                    "_blank"
                                )
                            }
                            style={{
                                alignItems: "center",
                                justifyContent: "center",
                                width: "260px",
                                height: "60px",
                                backgroundColor: `${COLOR.primaryColor}`,
                                borderRadius: "15px",
                                color: `${COLOR.bgColor}`,
                                cursor: "pointer",
                            }}
                        >
                            채용정보 보러가기
                        </Row>
                    </Row>
                </HireingContainerSub>
            </HireingContainer>
        </>
    );
};

export default Hireing;
