import * as React from "react";
const WmbLogo = (props: any) => (
    <svg
        id="_\uB808\uC774\uC5B4_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 210 24"
        role="img"
        {...props}
    >
        <defs>
            <style>{".cls-1{fill:#d2fa64;}"}</style>
        </defs>
        <g id="_\uADF8\uB8F9_3230">
            <path
                id="_\uD328\uC2A4_2045"
                className="cls-1"
                d="M24.9,15.18c.01,1.06-.05,2.12-.18,3.17-.44,3.07-3.12,5.32-6.22,5.21-2.29,.07-4.43-1.16-5.51-3.19-1.1,2.02-3.24,3.25-5.54,3.19-1.92,.09-3.76-.75-4.95-2.26-.6-.73-1-1.59-1.19-2.52-.22-1.19-.32-2.39-.29-3.6V1.09H7.19V15.36c-.03,.53,.05,1.06,.25,1.55,.22,.34,.62,.52,1.02,.46,.91,0,1.37-.67,1.37-2.01V1.09h6.26V15.36c-.04,.53,.05,1.06,.25,1.55,.22,.34,.62,.52,1.02,.46,.9,0,1.36-.67,1.36-2.01V1.09h6.19V15.18Z"
            />
            <path
                id="_\uD328\uC2A4_2046"
                className="cls-1"
                d="M39.7,23.16h-4.58c-.83,.01-1.67-.04-2.49-.15-.73-.13-1.44-.38-2.09-.74-.97-.5-1.8-1.26-2.38-2.18-.54-.86-.83-1.85-.84-2.86V8.95c-.04-1.15,.06-2.31,.31-3.44,.34-1.14,1.03-2.15,1.98-2.88,.72-.58,1.56-.99,2.46-1.19,1.11-.25,2.25-.37,3.39-.35h4.24V7.53h-4.21c-.34-.03-.69,.04-.99,.2-.24,.2-.35,.51-.31,.82,0,.74,.43,1.11,1.3,1.11h3.93v4.93h-4.55c-.34-.03-.67,.04-.97,.2-.23,.21-.34,.51-.29,.82,0,.74,.42,1.12,1.27,1.12h4.83v6.44Z"
            />
            <path
                id="_\uD328\uC2A4_2047"
                className="cls-1"
                d="M65.97,23.16h-6.19V8.89c.03-.53-.05-1.06-.25-1.55-.22-.34-.62-.52-1.02-.46-.91,0-1.36,.67-1.36,2.01v14.27h-6.25V8.89c.03-.53-.05-1.06-.25-1.55-.22-.34-.62-.52-1.02-.46-.91,0-1.36,.67-1.36,2.01v14.27h-6.19V9.04c-.01-1.06,.05-2.12,.19-3.17,.44-3.07,3.12-5.32,6.22-5.22,2.31-.06,4.45,1.19,5.54,3.22,1.79-3.09,5.74-4.14,8.82-2.36,.63,.37,1.2,.84,1.68,1.4,.6,.73,1.01,1.61,1.17,2.54,.2,1.18,.3,2.39,.28,3.59v14.12Z"
            />
            <path
                id="_\uD328\uC2A4_2048"
                className="cls-1"
                d="M84.3,23.16h-6.48V9.82c0-1.61-.46-2.42-1.39-2.42-.44-.06-.86,.18-1.05,.57-.2,.59-.28,1.22-.24,1.84v2.32h1.7v5.85h-1.7v5.17h-6.75V11.4c0-1.16,.01-2.01,.05-2.55,.05-.68,.17-1.35,.35-2,.4-1.79,1.36-3.39,2.75-4.58,1.36-1.09,3.06-1.66,4.8-1.61,2.57-.09,5,1.21,6.34,3.41,.67,1.06,1.12,2.24,1.33,3.47,.21,1.28,.3,2.57,.28,3.87v11.76Z"
            />
            <path
                id="_\uD328\uC2A4_2049"
                className="cls-1"
                d="M107.1,12.14c.03,2.13-.57,4.23-1.73,6.02-1.16,1.76-2.82,3.14-4.77,3.95-1.68,.7-3.48,1.06-5.29,1.05h-1.05v-6.59h1.15c1.35,.07,2.68-.36,3.73-1.22,.92-.82,1.42-2.01,1.38-3.24,0-2.95-2-4.42-6-4.43h-1.24v15.48h-6.59V1.09h7.71c1.05,0,2.09,.06,3.12,.2,1.06,.18,2.08,.48,3.06,.91,1.96,.79,3.62,2.16,4.78,3.93,1.17,1.78,1.78,3.87,1.75,6"
            />
            <path
                id="_\uD328\uC2A4_2050"
                className="cls-1"
                d="M125.25,16.69c.08,1.74-.61,3.42-1.89,4.6-1.49,1.22-3.38,1.83-5.3,1.71h-1.32v-5.51h1.41c.36,.04,.73-.07,1.02-.3,.23-.33,.34-.73,.3-1.13,.04-.39-.07-.77-.31-1.07-.29-.21-.64-.32-1-.28h-1.41v-5.38h.5c.36,.04,.73-.07,1.02-.3,.24-.32,.35-.73,.3-1.13,.05-.38-.07-.76-.31-1.05-.29-.21-.64-.31-1-.28h-1.5V23.01h-6.66V.88h8.19c1.87-.14,3.73,.35,5.3,1.38,1.23,1.04,1.89,2.61,1.77,4.22,0,1.83-.86,3.54-2.34,4.61,2.08,1.08,3.34,3.26,3.25,5.6"
            />
            <path
                id="_\uD328\uC2A4_2051"
                className="cls-1"
                d="M137.18,22.95h-1.5c-1.1,.03-2.2-.13-3.25-.45-.92-.31-1.76-.81-2.47-1.47-.98-.91-1.67-2.1-1.97-3.4-.27-1.4-.38-2.82-.34-4.24V.88h6.66V13.91c0,1.77,.75,2.66,2.25,2.66l.62-.03v6.4Z"
            />
            <path
                id="_\uD328\uC2A4_2052"
                className="cls-1"
                d="M162.42,11.91c0,1.31-.22,2.6-.66,3.83-.44,1.25-1.1,2.41-1.94,3.43-1.07,1.33-2.43,2.39-3.97,3.1-2.81,1.26-5.99,1.41-8.91,.42-4.6-1.58-7.69-5.92-7.69-10.79-.04-3.05,1.2-5.97,3.41-8.07,3.31-3.3,8.27-4.31,12.6-2.55,1.42,.59,2.7,1.46,3.78,2.55,2.19,2.11,3.41,5.03,3.38,8.07m-6.53,.02c-.02-2.77-2.26-5.01-5.03-5.02-1.34-.02-2.64,.51-3.58,1.47-1.96,1.93-1.98,5.09-.04,7.05,0,0,.02,.02,.03,.03,2,1.93,5.17,1.94,7.18,.01,.96-.93,1.49-2.21,1.46-3.54"
            />
            <path
                id="_\uD328\uC2A4_2053"
                className="cls-1"
                d="M187.58,11.92c.12,3.1-1.09,6.11-3.33,8.26-2.24,2.12-5.22,3.26-8.3,3.16-4.25,.01-8.16-2.28-10.22-5.99-2-3.74-1.75-8.27,.62-11.78,1.06-1.58,2.49-2.88,4.18-3.77,4.16-2.19,9.23-1.71,12.91,1.23,2.11,1.78,3.51,4.25,3.95,6.98l-6.81,.43c-1-2.08-2.48-3.12-4.44-3.12-1.24-.02-2.43,.46-3.3,1.34-1.8,1.79-1.82,4.7-.03,6.51h.01c.88,.89,2.07,1.37,3.31,1.34,1.16,.06,2.3-.36,3.15-1.16,.86-.94,1.38-2.15,1.48-3.42h6.82Z"
            />
            <path
                id="_\uD328\uC2A4_2054"
                className="cls-1"
                d="M196.55,22.95h-6.66V.88h6.66V22.95Zm12.44,0h-7.44l-4.78-11.98,5.28-10.09h6.94l-5.57,10.09,5.57,11.98Z"
            />
        </g>
    </svg>
);
export default WmbLogo;
