import styled from "styled-components";
import { Col, Row, SText } from "../components";
import { COLOR } from "../const";
import { IconBrandInstagram } from "@tabler/icons";
import { Link } from "react-router-dom";
import useLayout from "../hooks/useLayout";

const FooterContainer = styled.footer<{ isTabletWidth: boolean }>`
    display: flex;
    flex-direction: row;
    position: relative;
    height: ${({ isTabletWidth }) => (isTabletWidth ? "150px" : "227px")};
    width: 100%;
    flex: 0 2 auto;
    justify-content: center;
    border-top: 1px solid ${COLOR.borderColor};
    color: ${COLOR.textColor.white};
    background-color: ${COLOR.bgColor};
    z-index: 3;
`;

const FooterContainerSub = styled(Col)<{ isTabletWidth: boolean }>`
    height: ${({ isTabletWidth }) => (isTabletWidth ? "150px" : "227px")};
    max-width: 1920px;
    flex: 1 1 auto;
    padding: ${({ isTabletWidth }) =>
        isTabletWidth ? "21px 30px" : "60px 220px"};
`;

const Footer = () => {
    const { isTabletWidth, isMobileWidth } = useLayout();
    return (
        <FooterContainer isTabletWidth={isTabletWidth}>
            <FooterContainerSub isTabletWidth={isTabletWidth}>
                <Row style={{ justifyContent: "space-between" }}>
                    <Row>
                        <Link to="/" style={{ cursor: "pointer" }}>
                            <SText
                                fontType={isTabletWidth ? "M.12" : "M.16"}
                                children="회사소개"
                            />
                        </Link>
                        <Link
                            to="/culture"
                            style={{ marginLeft: "50px", cursor: "pointer" }}
                        >
                            <SText
                                fontType={isTabletWidth ? "M.12" : "M.16"}
                                children="WMB Culture"
                            />
                        </Link>
                        <Link
                            to="/hireing"
                            style={{ marginLeft: "50px", cursor: "pointer" }}
                        >
                            <SText
                                fontType={isTabletWidth ? "M.12" : "M.16"}
                                children="채용공고"
                            />
                        </Link>
                    </Row>
                    {!isMobileWidth && (
                        <Row>
                            <Row style={{ cursor: "pointer" }}>
                                <SText
                                    fontType={isTabletWidth ? "M.12" : "M.16"}
                                    children="KOR"
                                />
                            </Row>
                            <Row
                                style={{
                                    marginLeft: "40px",
                                    cursor: "pointer",
                                }}
                            >
                                <SText
                                    fontType={isTabletWidth ? "M.12" : "M.16"}
                                    children="ENG"
                                />
                            </Row>
                        </Row>
                    )}
                </Row>
                <Row
                    style={{
                        justifyContent: "space-between",
                        marginTop: "40px",
                    }}
                >
                    <SText
                        fontType={isTabletWidth ? "M.10" : "M.13"}
                        children="서울 성동구 상원12길 34, 서울숲에이원센터 11층 (주)위매드블록"
                    />
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                            window.open(
                                "https://www.instagram.com/wmb_recruit/",
                                "_blank"
                            )
                        }
                    >
                        <IconBrandInstagram width={20} height={20} />
                    </div>
                </Row>
                <Row
                    style={{
                        justifyContent: "space-between",
                        marginTop: "16px",
                    }}
                >
                    <SText
                        fontType={isTabletWidth ? "M.10" : "M.13"}
                        children="©WEMADBLOCK. All Rights Reserved."
                    />
                </Row>
            </FooterContainerSub>
        </FooterContainer>
    );
};

export default Footer;
