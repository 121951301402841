import React, { useEffect } from "react";
import { Col } from "./components";
import { COLOR } from "./const";
import Nav from "./nav";
import Footer from "./footer";
import styled from "styled-components";
import Hireing from "./pages/hireing";
import Culture from "./pages/culture";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Introduction from "./pages/introduction";
import { HelmetProvider } from "react-helmet-async";

const AppContainer = styled(Col)`
  position: relative;
  /* height: 100vh; */
  /* width: 100vw; */
  background-color: ${COLOR.bgColor};
`;

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <ScrollToTop />
        <AppContainer>
          <Nav />
          <Routes>
            <Route path="*" element={<Introduction />} />
            <Route path="/" element={<Introduction />} />
            <Route path="/culture" element={<Culture />} />
            <Route path="/hireing" element={<Hireing />} />
          </Routes>

          <Footer />
        </AppContainer>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
