import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Row, SText } from "../components";
import { COLOR } from "../const";
import useLayout from "../hooks/useLayout";
import { WmbLogo } from "../images";
import { Language, Menu } from "../types";

const NavContainer = styled.nav`
    display: flex;
    flex-direction: row;
    position: absolute;
    height: 64px;
    width: 100%;
    flex: 0 2 auto;
    justify-content: center;
    background-color: ${COLOR.bgColor};
    border-bottom: 1px solid ${COLOR.borderColor};
    z-index: 1;
`;

const NavContainerSub = styled(Row)<{ isTabletWidth: boolean }>`
    height: 64px;
    align-items: center;
    padding: ${({ isTabletWidth }) =>
        isTabletWidth ? "21px 30px" : "21px 220px"};
    max-width: 1920px;
    flex: 1 1 auto;
    color: ${COLOR.textColor.white};
    justify-content: space-between;
`;

const LanguageWrapper = styled.li<{ selected: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ selected }) =>
        selected ? COLOR.textColor.white : COLOR.textColor.gray};
    cursor: pointer;
    transition: all 0.3s ease 0s;
    :hover {
        color: ${COLOR.textColor.white};
    }
`;

const MenuWrapper = styled(Row)<{ selected: boolean }>`
    cursor: pointer;
    transition: all 0.3s ease 0s;
    color: ${({ selected }) =>
        selected ? COLOR.primaryColor : COLOR.textColor.white};
    :hover {
        color: ${COLOR.primaryColor};
    }
`;

const Nav = () => {
    const { isTabletWidth, isMobileWidth } = useLayout();
    const [language, setLanguage] = useState<Language>(Language.KOR);
    const [menu, setMenu] = useState<Menu>(Menu.Introduction);
    const location = useLocation();

    useEffect(() => {
        setMenu(Menu.Introduction);
        if (location.pathname.includes("culture")) {
            setMenu(Menu.Culture);
        }
        if (location.pathname.includes("hireing")) {
            setMenu(Menu.Hireing);
        }
    }, [location]);

    return (
        <NavContainer>
            <NavContainerSub isTabletWidth={isTabletWidth}>
                <Row
                    style={{
                        justifyContent: `${
                            isTabletWidth ? "space-between" : "flex-start"
                        }`,
                        flex: "1 1 auto",
                    }}
                >
                    <Link to="/" style={{ cursor: "pointer" }}>
                        {isMobileWidth ? (
                            <WmbLogo width={130} />
                        ) : isTabletWidth ? (
                            <WmbLogo width={154} />
                        ) : (
                            <WmbLogo width={208} />
                        )}
                    </Link>
                    <ul
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: `${isMobileWidth ? "10px" : "100px"}`,
                        }}
                    >
                        <li>
                            <Link to="/">
                                <MenuWrapper
                                    selected={menu === Menu.Introduction}
                                    onClick={() => setMenu(Menu.Introduction)}
                                >
                                    <SText
                                        fontType={
                                            isMobileWidth
                                                ? "M.10"
                                                : isMobileWidth
                                                ? "M.12"
                                                : "M.16"
                                        }
                                        children="회사소개"
                                    />
                                </MenuWrapper>
                            </Link>
                        </li>
                        <li>
                            <Link to="/culture">
                                <MenuWrapper
                                    selected={menu === Menu.Culture}
                                    onClick={() => setMenu(Menu.Culture)}
                                >
                                    <SText
                                        fontType={
                                            isMobileWidth
                                                ? "M.10"
                                                : isMobileWidth
                                                ? "M.12"
                                                : "M.16"
                                        }
                                        style={{
                                            marginLeft: `${
                                                isMobileWidth ? "20px" : "40px"
                                            }`,
                                        }}
                                        children={
                                            isMobileWidth
                                                ? "Culture"
                                                : "WMB Culture"
                                        }
                                    />
                                </MenuWrapper>
                            </Link>
                        </li>
                        <li>
                            <Link to="/hireing">
                                <MenuWrapper
                                    selected={menu === Menu.Hireing}
                                    onClick={() => setMenu(Menu.Hireing)}
                                >
                                    <SText
                                        fontType={
                                            isMobileWidth
                                                ? "M.10"
                                                : isMobileWidth
                                                ? "M.12"
                                                : "M.16"
                                        }
                                        style={{
                                            marginLeft: `${
                                                isMobileWidth ? "20px" : "40px"
                                            }`,
                                        }}
                                        children="채용공고"
                                    />
                                </MenuWrapper>
                            </Link>
                        </li>
                    </ul>
                </Row>

                {!isTabletWidth && (
                    <ul style={{ display: "flex", alignItems: "center" }}>
                        <LanguageWrapper
                            selected={language === Language.KOR}
                            onClick={() => setLanguage(Language.KOR)}
                        >
                            <SText fontType="M.16" children="KOR" />
                        </LanguageWrapper>

                        <LanguageWrapper
                            selected={language === Language.ENG}
                            // onClick={() => setLanguage(Language.ENG)}
                            onClick={() => setLanguage(Language.KOR)}
                        >
                            <SText
                                fontType="M.16"
                                style={{ marginLeft: "40px" }}
                                children="ENG"
                            />
                        </LanguageWrapper>
                    </ul>
                )}
            </NavContainerSub>
        </NavContainer>
    );
};

export default Nav;
