import styled from "styled-components";
import { Col, Row, SText } from "../../components";
import { COLOR } from "../../const";
import useLayout from "../../hooks/useLayout";

import culture1 from "../../images/culture1.jpeg";
import culture2 from "../../images/culture2.jpeg";
import { Helmet } from "react-helmet-async";

const CultureContainer = styled(Row)`
  width: 100%;
  flex: 0 2 auto;
  justify-content: center;
  color: ${COLOR.textColor.white};
  margin-top: 64px;
`;

const CultureContainerSub = styled.main<{ isTabletWidth: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 1920px;
  padding: ${({ isTabletWidth }) =>
    isTabletWidth ? "84px 30px" : "84px 220px"};
`;
const ListCircle = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${COLOR.textColor.white};
  margin-right: 14px;
`;

const TextContainer = styled(Row)<{ isTabletWidth: boolean }>`
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: ${({ isTabletWidth }) => (isTabletWidth ? "column" : "row")};
`;

const Section = styled.section<{ isTabletWidth: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isTabletWidth }) => (isTabletWidth ? "100%" : "50%")};
  margin-top: 60px;
`;

const FlexibleImgWrapper = styled(Row)<{ url: string }>`
  margin-top: 60px;
  height: 0px;
  padding-bottom: 56%;
  background-image: ${({ url }) => `url(${url})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const SHr = styled(Row)`
  flex: 1 1 auto;
  height: 1px;
  background-color: ${COLOR.borderColor};
  margin-top: 60px;
`;

const SUl = styled.ul`
  display: flex;
  align-items: center;
`;

const SLi = styled.li`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const Culture = () => {
  const { isMobileWidth, isTabletWidth } = useLayout();

  return (
    <>
      <Helmet>
        <title>Culture - 위매드블록</title>
      </Helmet>
      <CultureContainer>
        <CultureContainerSub isTabletWidth={isTabletWidth}>
          <h2>
            <SText
              fontType={isTabletWidth ? "SB.14" : "SB.24"}
              color={COLOR.primaryColor}
              children="WMB Culture"
            />
          </h2>
          <h3>
            <SText
              fontType={
                isMobileWidth ? "SB.26" : isTabletWidth ? "SB.34" : "SB.64"
              }
              style={{ marginTop: "15px" }}
              children="우리가 창의력을 표현하는 방식"
            />
          </h3>
          <FlexibleImgWrapper url={culture1}>
            <img
              src={culture1}
              alt="culture1"
              style={{
                display: "none",
              }}
            />
          </FlexibleImgWrapper>
          <p>
            <SText
              fontType={isTabletWidth ? "SB.16" : "SB.26"}
              style={{ marginTop: "60px" }}
              children="WMB의 팀원 모두는 가장 트렌디한 산업을 이끄는 사람들이라는 자부심을 갖고 있습니다."
            />
            <SText
              fontType={isTabletWidth ? "SB.16" : "SB.26"}
              style={{ marginTop: "15px" }}
              children="새로운 시대를 여는 기술을 한발 앞서 탐구하고,"
            />
            <SText
              fontType={isTabletWidth ? "SB.16" : "SB.26"}
              style={{ marginTop: "15px" }}
              children="우리의 기술이 담긴 서비스가 세상에 어떤 긍정적인 영향을 줄 수 있을지 항상 고민합니다."
            />
            <SText
              fontType={isTabletWidth ? "SB.16" : "SB.26"}
              style={{ marginTop: "15px" }}
              children="난관에 봉착하더라도 스스로 또는 함께 극복하며 우리는 성장하고 있습니다."
            />
          </p>
          <Col
            style={{
              marginTop: `${isTabletWidth ? "170px" : "340px"}`,
            }}
          >
            <strong>
              <Row>
                <SText
                  fontType={
                    isMobileWidth ? "M.23" : isTabletWidth ? "M.27" : "M.54"
                  }
                  color={COLOR.primaryColor}
                  style={{
                    marginTop: `${isMobileWidth ? "5px" : "15px"}`,
                  }}
                  // style={{ marginTop: "15px" }}
                  children="WMB의 핵심가치"
                />
                <SText
                  fontType={
                    isMobileWidth ? "M.23" : isTabletWidth ? "M.27" : "M.54"
                  }
                  style={{
                    marginTop: `${isMobileWidth ? "5px" : "15px"}`,
                  }}
                  children="는"
                />
              </Row>
              <SText
                fontType={
                  isMobileWidth ? "M.23" : isTabletWidth ? "M.27" : "M.54"
                }
                style={{
                  marginTop: `${isMobileWidth ? "5px" : "15px"}`,
                }}
                children="우리가 만드는 모든 서비스와 경험"
              />
              <SText
                fontType={
                  isMobileWidth ? "M.23" : isTabletWidth ? "M.27" : "M.54"
                }
                style={{
                  marginTop: `${isMobileWidth ? "5px" : "15px"}`,
                }}
                children="그리고 조직 문화의 기준이 됩니다."
              />
            </strong>
          </Col>

          <SHr />
          <Col>
            <TextContainer isTabletWidth={isTabletWidth}>
              <Section isTabletWidth={isTabletWidth}>
                <SText
                  fontType={isTabletWidth ? "M.14" : "M.20"}
                  color={COLOR.primaryColor}
                  children="신뢰 위의 자율"
                />
                <SText
                  fontType={isTabletWidth ? "SB.22" : "SB.32"}
                  color={COLOR.textColor.white}
                  style={{ marginTop: "10px" }}
                  children="자율적인 조직문화"
                />
                <Col style={{ marginTop: "20px" }}>
                  <SText
                    fontType={isTabletWidth ? "M.14" : "M.20"}
                    children="구성원 간 쌓은 신뢰를 바탕으로 한 자율적인 조직문화를 지향합니다."
                  />
                </Col>
              </Section>

              <Section isTabletWidth={isTabletWidth}>
                <SText
                  fontType={isTabletWidth ? "M.14" : "M.20"}
                  color={COLOR.primaryColor}
                  children="창의적인 조직"
                />
                <SText
                  fontType={isTabletWidth ? "SB.22" : "SB.32"}
                  color={COLOR.textColor.white}
                  style={{ marginTop: "10px" }}
                  children="솔직한 커뮤니케이션"
                />
                <Col style={{ marginTop: "20px" }}>
                  {!isMobileWidth ? (
                    <>
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        children="자율적 조직문화의 핵심은 직급, 직책, 경력 등에 구애받지 않는"
                      />
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        children="구성원 간의 솔직한 소통입니다. 솔직한 소통이 창의적인 조직을"
                      />
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        children="만들어갑니다."
                      />
                    </>
                  ) : (
                    <SText
                      fontType="M.14"
                      children="자율적 조직문화의 핵심은 직급, 직책, 경력 등에 구애받지 않는 
                                        구성원 간의 솔직한 소통입니다. 
                                        솔직한 소통이 창의적인 조직을 만들어 갑니다."
                    />
                  )}
                </Col>
              </Section>
            </TextContainer>

            <TextContainer isTabletWidth={isTabletWidth}>
              <Section isTabletWidth={isTabletWidth}>
                <SText
                  fontType={isTabletWidth ? "M.14" : "M.20"}
                  color={COLOR.primaryColor}
                  children="상호존중"
                />
                <SText
                  fontType={isTabletWidth ? "SB.22" : "SB.32"}
                  color={COLOR.textColor.white}
                  style={{ marginTop: "10px" }}
                  children="배려하는 마음"
                />
                <Col style={{ marginTop: "20px" }}>
                  {!isMobileWidth ? (
                    <>
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        children="타인을 존중할 줄 아는 사람만이 존중 받을 자격이 있습니다."
                      />
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        children="최고의 팀워크는 상호존중하고 배려하는 마음에서부터 시작합니다."
                      />
                    </>
                  ) : (
                    <SText
                      fontType="M.14"
                      children="타인을 존중할 줄 아는 사람만이 존중 받을 자격이 있습니다. 
                                        최고의 팀워크는 상호존중하고 배려하는 마음에서부터 시작합니다."
                    />
                  )}
                </Col>
              </Section>

              <Section isTabletWidth={isTabletWidth}>
                <SText
                  fontType={isTabletWidth ? "M.14" : "M.20"}
                  color={COLOR.primaryColor}
                  children="프로다운"
                />
                <SText
                  fontType={isTabletWidth ? "SB.22" : "SB.32"}
                  color={COLOR.textColor.white}
                  style={{ marginTop: "10px" }}
                  children="프로답게 일하는 문화"
                />
                <Col style={{ marginTop: "20px" }}>
                  {!isMobileWidth ? (
                    <>
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        children="프로답게 일한다는 것은 스스로 특정 문제를 인식하고 문제에 대한"
                      />
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        children="해결책을 얼마나 잘 찾고 빠르게 수행하는지에서 나타납니다."
                      />
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        children="위매드블록은 구성원 모두가 프로답게 일하는 문화를 지향합니다."
                      />
                    </>
                  ) : (
                    <SText
                      fontType="M.14"
                      children="프로답게 일한다는 것은 스스로 특정 문제를 인식하고 문제에 대한 
                                    해결책을 얼마나 잘 찾고 빠르게 수행하는지에서 나타납니다.
                                    위매드블록은 구성원 모두가 프로답게 일하는 문화를 지향합니다.
                                    "
                    />
                  )}
                </Col>
              </Section>
            </TextContainer>
          </Col>

          <FlexibleImgWrapper url={culture2}>
            <img
              src={culture2}
              alt="culture2"
              style={{
                display: "none",
              }}
            />
          </FlexibleImgWrapper>

          <Col style={{ marginTop: "60px" }}>
            <Col>
              <SText
                fontType={
                  isMobileWidth ? "M.23" : isTabletWidth ? "M.27" : "M.54"
                }
                style={{
                  marginTop: `${isMobileWidth ? "5px" : "15px"}`,
                }}
                children="WMB가 진심이 될 수 있게,"
              />
              <SText
                fontType={
                  isMobileWidth ? "M.23" : isTabletWidth ? "M.27" : "M.54"
                }
                color={COLOR.primaryColor}
                style={{
                  marginTop: `${isMobileWidth ? "5px" : "15px"}`,
                }}
                children="진심을 다하는 복지"
              />
            </Col>
          </Col>
          <SHr />

          <Col>
            <TextContainer isTabletWidth={isTabletWidth}>
              <Section isTabletWidth={isTabletWidth}>
                <SText
                  fontType={isTabletWidth ? "SB.22" : "SB.32"}
                  color={COLOR.primaryColor}
                  children="자율적인 근무환경"
                />
                <Col style={{ marginTop: "20px" }}>
                  {!isMobileWidth ? (
                    <>
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        children="사람마다 일하기 좋은 시간은 다릅니다. 개인의 판단을 존중하여 최고의 효율로"
                      />
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        style={{ marginTop: "7px" }}
                        children="일할 수 있는 환경을 만들고, 직급을 없애 수평적인 조직 문화를 위해 노력해요."
                      />
                    </>
                  ) : (
                    <SText
                      fontType="M.14"
                      style={{ marginTop: "7px" }}
                      children="사람마다 일하기 좋은 시간은 다릅니다. 개인이 판단을 존중하여 최고의 효율로
                                일할 수 있는 환경을 만들고, 직급을 없애 수평적인 조직 문화를 위해 노력해요.
                                
                                "
                    />
                  )}
                  <Col style={{ marginTop: "40px" }}>
                    <SUl>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="탄력근무제 진행"
                      />
                    </SUl>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="자유로운 연차 사용"
                      />
                    </SLi>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="직급이 아닌 닉네임 호칭"
                      />
                    </SLi>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="개성있는 캐릭터 프로필"
                      />
                    </SLi>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="최고 사양 PC/모니터 지원"
                      />
                    </SLi>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="유니폼 및 웰컴키트 지급"
                      />
                    </SLi>
                  </Col>
                </Col>
              </Section>

              <Section isTabletWidth={isTabletWidth}>
                <SText
                  fontType={isTabletWidth ? "SB.22" : "SB.32"}
                  color={COLOR.primaryColor}
                  children="함께 일하는 멋진 동료들"
                />
                <Col style={{ marginTop: "20px" }}>
                  {!isMobileWidth ? (
                    <>
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        children="하고 싶은 말은 꽁꽁 숨기지 말고 꼭 하기로 해요. 경영지원팀과 티타임을 갖고"
                      />
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        style={{ marginTop: "7px" }}
                        children="다른 부서 팀원들과도 친해질 수 있는 기회를 만들어 함께 성장합니다."
                      />
                    </>
                  ) : (
                    <SText
                      fontType="M.14"
                      children="하고 싶은 말은 꽁꽁 숨기지 말고 꼭 하기로 해요. 경영지원팀과 티타임을 갖고
                                다른 부서 팀원들과도 친해질 수 있는 기회를 만들어 함께 성장합니다."
                    />
                  )}
                  <Col style={{ marginTop: "40px" }}>
                    <SUl>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="회식비 지원"
                      />
                    </SUl>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="생일선물 지원"
                      />
                    </SLi>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="패밀리데이 운영"
                      />
                    </SLi>
                  </Col>
                </Col>
              </Section>
            </TextContainer>

            <TextContainer isTabletWidth={isTabletWidth}>
              <Section isTabletWidth={isTabletWidth}>
                <SText
                  fontType={isTabletWidth ? "SB.22" : "SB.32"}
                  color={COLOR.primaryColor}
                  children="풍요로운 삶"
                />
                <Col style={{ marginTop: "20px" }}>
                  {!isMobileWidth ? (
                    <>
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        children="진정한 복지는 풍요로운 삶을 만들어 주는 것에서 시작한다고 생각합니다."
                      />
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        style={{ marginTop: "7px" }}
                        children="건강하고 행복하게 일할 수 있게 우리는 아끼지 않죠."
                      />
                    </>
                  ) : (
                    <SText
                      fontType="M.14"
                      children="진정한 복지는 풍요로운 삶을 만들어 주는 것에서 시작한다고 생각합니다. 
                            건강하고 행복하게 일할 수 있게 우리는 아끼지 않죠."
                    />
                  )}
                  <Col style={{ marginTop: "40px" }}>
                    <SUl>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="연봉 외 인센티브 제도 운영"
                      />
                    </SUl>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="프로젝트 코인 지급"
                      />
                    </SLi>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="복지포인트 지급"
                      />
                    </SLi>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="명절선물 지원"
                      />
                    </SLi>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="경조사비 및 경조휴가 지원"
                      />
                    </SLi>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="스낵바 제공"
                      />
                    </SLi>
                  </Col>
                </Col>
              </Section>

              <Section isTabletWidth={isTabletWidth}>
                <SText
                  fontType={isTabletWidth ? "SB.22" : "SB.32"}
                  color={COLOR.primaryColor}
                  children="업무 성장 지원"
                />
                <Col style={{ marginTop: "20px" }}>
                  {!isMobileWidth ? (
                    <>
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        children="뛰어난 동료와 함께 성장합니다. 위매드블록은 구성원과 함께 성장하기"
                      />
                      <SText
                        fontType={isTabletWidth ? "M.14" : "M.20"}
                        style={{ marginTop: "7px" }}
                        children="때문에 성장을 위한 지원은 계속됩니다."
                      />
                    </>
                  ) : (
                    <SText
                      fontType="M.14"
                      children="뛰어난 동료와 함께 성장합니다. 위매드블록은 구성원과 함께 성장하기 때문에 
                                        성장을 위한 지원은 계속됩니다."
                    />
                  )}
                  <Col style={{ marginTop: "40px" }}>
                    <SUl>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="업무관련 도서비 지원"
                      />
                    </SUl>
                    <SLi>
                      <ListCircle />
                      <SText
                        fontType={isTabletWidth ? "M.12" : "M.16"}
                        children="업무관련 세미나, 교육 지원"
                      />
                    </SLi>
                  </Col>
                </Col>
              </Section>
            </TextContainer>
          </Col>
        </CultureContainerSub>
      </CultureContainer>
    </>
  );
};

export default Culture;
