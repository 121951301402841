import { useEffect, useState, useMemo } from "react";

const DESKTOP_WIDE = 1920;
const DESKTOP = 1390;
const TABLET = 1024;
const MOBILE = 480;

const media = {
    overWideDesktop: `(min-width: ${DESKTOP_WIDE + 1}px)`,
    overDesktop: `(min-width: ${DESKTOP + 1}px)`,
    mobile: `(max-width: ${MOBILE}px)`,
    tablet: `(max-width: ${TABLET}px)`,
};

const useLayout = (): {
    isDesktopWidth: boolean;
    isTabletWidth: boolean;
    isMobileWidth: boolean;
    innerWidth: number;
} => {
    const [innerWidth, setInnerWidth] = useState(0);
    const [isMobileWidth, setIsMobileWidth] = useState<boolean>(
        window.innerWidth < MOBILE
    );
    const [isTabletWidth, setIsTabletWidth] = useState<boolean>(
        window.innerWidth < TABLET
    );

    const isDesktopWidth = useMemo(() => {
        return false === isTabletWidth && false === isMobileWidth;
    }, [isTabletWidth, isMobileWidth]);

    const updateSize = (): void => {
        setIsMobileWidth(window.innerWidth < MOBILE);
        setIsTabletWidth(window.innerWidth < TABLET);
        setInnerWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateSize);
        updateSize();

        return (): void => {
            window.removeEventListener("resize", updateSize);
        };
    }, []);

    return {
        isTabletWidth,
        isMobileWidth,
        isDesktopWidth,
        innerWidth,
    };
};

export default useLayout;
